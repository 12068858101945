
<script>
  const showNotification = (title, body, status = 'success', timeout = 5000) => {
    const icon = (status === 'success') ? 'check' : status === 'error' ? 'close' : 'info';
    const message = `<div class="uk-grid-divider uk-grid-match uk-padding-remove" uk-grid><span uk-icon="icon: ${icon}; ratio: 2;"></span><div class="uk-width-expand message uk-text-left"><h1>${title}</h1>${body}</div></div>`;
    window.UIkit.notification({
      message,
      status,
      pos: 'bottom-right',
      timeout
    });
    const notificationElements = document.querySelectorAll('.uk-notification-message')
    if (notificationElements?.length && status !== 'default') {
      notificationElements[notificationElements?.length - 1].style.background = status === 'success' ? '#057f52' :  status === 'error' ? '#8a0000' : '#de8b4f'
    }
  }

  export default {
    showNotification
  }

</script>
